import * as React from "react"
import { Container,Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../components/seo"
import Menu from "../components/festival/menu"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  textBox: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    height: "100%",
    clipPath: "polygon(0 0, 100% 1%, 100% 84%, 0% 100%)"
  },
  boxText: {
    color: theme.palette.background.default,
    fontWeight: 800
  },
  logoBox: {
    borderTop: "2px solid white",
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}));


const IndexPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
    <Seo title="Tomatostories" />
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <Menu/>
            </Grid>
        
            <Grid item md={8} xs={12}>
              <StaticImage 
                          src="../images/foto-kowlier.jpg" 
                          alt="Tomato Festival" 
                          placeholder="blurred"
                          />    
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={classes.textBox}>
                <Typography className={classes.boxText} variant="body2">
                Deze eerste editie van TOMATO FESTIVAL zal plaatsvinden van 18 - 20 augustus 2021 onder het regenproof afdak van Bolwerk.
                <br/><br/>
                Omwille van je-weet-wel-wat zal het in iets beperktere kring doorgaan dan we zouden willen, maar van muzikale beperking is absoluut géén sprake. Met de beste artiesten van het moment genieten we samen van wat we zo lang hebben moeten missen: LIVE-MUZIEK!
                <br/><br/>
                VZW TOMATO is een samenstelling van verschillende projecten met 1 gezamenlijk doel:
                Muziek voor iedereen! 
                <br/><br/>
                Wij zijn Thijs, Jille en Oole, muziekliefhebbers in hart en nieren, en samen vormen we een team waarin zowel technische kennis, als ook boekings en management gebundeld is.
                Met deze inzet willen we verschillende muziekevenementen organiseren, jong talent ondersteunen en nieuwe kansen te creëren.

                </Typography>
              </Box>
            </Grid>
        </Grid>
        <Box className={classes.logoBox}>
          <StaticImage 
            src="../images/Vlaanderen_verbeelding.png"
            alt="verbeelding werkt"
            placeholder="blurred"
            height={80}
          />
        </Box>
    </Container>

)}

export default IndexPage
